import { FC } from 'react'
import { useRouter } from 'next/router'
import {
  GlobalDesktopHeaderNavMenu,
  GlobalDeskTopNavMenuItem,
} from '@components-v2/molecules/GlobalDesktopHeaderNavMenu'
import BriefCaseSvg from '@/public/images/common/briefcase-01_v2.svg'
import FileSvg from '@/public/images/common/file-02_v2.svg'
import PenSvg from '@/public/images/common/pen-tool-01_v2.svg'
import RocketSvg from '@/public/images/common/rocket-01_v2.svg'
import PeopleSvg from '@/public/images/common/users-01_v2.svg'
import BuildingSvg from '@/public/images/common/building-01_v2.svg'
import CoinsHandSvg from '@/public/images/common/coins-hand_v2.svg'
import PieChartSvg from '@/public/images/common/untitled-ui-icons/pie-chart-02.svg'
import AwardSvg from '@/public/images/common/untitled-ui-icons/award-01.svg'
import GiftSvg from '@/public/images/common/untitled-ui-icons/gift-01.svg'
import CurrencyYenSvg from '@/public/images/common/untitled-ui-icons/currency-yen-circle.svg'
import GraduationHatSvg from '@/public/images/common/untitled-ui-icons/graduation-hat-02.svg'
import MessageSmileSvg from '@/public/images/common/untitled-ui-icons/message-smile-square.svg'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import { GlobalDeskTopNavSubMenu } from '@/componentsV2/molecules/GlobalDesktopHeaderNavMenuDropdown'
import { GlobalDeskTopNavMenuItemChild } from '@/componentsV2/molecules/NavMenuItems'
import styles from './index.module.scss'

const MENU_KEY = {
  ANGELS: 'angels',
  JOB_SEEKERS: 'job seekers',
  ARTICLES: 'articles',
  FUND_MANAGERS: 'fund managers',
  STARTUPS: 'startups',
  NONE: 'none',
}
Object.freeze(MENU_KEY)

type MenuItem = GlobalDeskTopNavMenuItem & {
  key: string
}

export const GlobalDesktopHeaderNavigation: FC = observer(() => {
  const router = useRouter()
  const { t } = useTranslation(['global'])
  const rawMenuItems: MenuItem[] = [
    {
      key: MENU_KEY.STARTUPS,
      name: t('スタートアップ向け'),
      isMultipleColumns: router.locale === 'ja', // 日本語のみメニューが長くなりすぎるので複数カラム
      subMenus: [
        {
          name: t('資金調達'),
          column: 0,
          subMenuItems: [
            {
              label: t('Angel Investor Matching'),
              description: t('成長を支援するエンジェル投資家を探し繋がる'),
              href: '/angels',
              iconSvgNode: <RocketSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('TOSHIKA DB'),
              description: t('VC、CVC、上場企業、非上場企業の中から、あなたに最適な投資家を探す'),
              href: 'https://invdb.protocol.ooo/',
              iconSvgNode: <PeopleSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
            {
              label: t('Fundraising PROTOCOL'),
              description: t('エクイティの資金調達をワンストップで支援'),
              href: 'https://toshika-lp.protocol.ooo/',
              iconSvgNode: <BuildingSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
        {
          name: t('エグジット'),
          column: 0,
          subMenuItems: [
            {
              label: t('PRO M&A'),
              description: t('スタートアップのM&Aによるエグジットを支援'),
              href: `https://ma.protocol.ooo/${router.locale === 'ja' ? '1' : '1-1'}`,
              iconSvgNode: <CoinsHandSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.ANGELS,
      name: t('投資家向け'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('ソーシング'),
          subMenuItems: [
            {
              label: t('Startup Matching'),
              description: t('興味があるスタートアップを探し繋がる。'),
              href: '/startups',
              iconSvgNode: <RocketSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('Investment Management Cloud'),
              description: t('投資案件の探索から投資先の管理まで、一貫して対応可能なクラウドサービス'),
              href: 'https://prosupporters.protocol.ooo/investorcloud',
              iconSvgNode: <AwardSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
            {
              label: t('Startup Offer'),
              description: t(
                'PROTOCOLが厳選したスタートアップを定期的にご紹介し、スタートアップに対して逆オファーを行う'
              ),
              href: 'https://toshika-lp.protocol.ooo/toshika-investor-partners',
              iconSvgNode: <PeopleSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.ARTICLES,
      name: t('メディア'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('編集部投稿'),
          subMenuItems: [
            {
              label: t('インタビュー記事'),
              description: t('編集部が投稿したインタビュー記事をチェック'),
              href: '/articles/categories/interviews',
              iconSvgNode: <FileSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('特集記事'),
              description: t('編集部が投稿したスタートアップ動向の記事をチェック'),
              href: '/articles/categories/features',
              iconSvgNode: <FileSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
        {
          name: t('コミュニティ投稿'),
          subMenuItems: [
            {
              label: t('ストーリー'),
              description: t('見識とつながりを深める、コミュニティの投稿を探そう'),
              href: '/articles/categories/stories',
              iconSvgNode: <PenSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.JOB_SEEKERS,
      name: t('求職者向け'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('求人'),
          subMenuItems: [
            {
              label: t('求人を探す'),
              description: t('次なるキャリアを築くための求人募集を見つけよう'),
              href: '/jobs',
              iconSvgNode: <BriefCaseSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
      ],
    },
  ]

  const contactSalesMenuItems: GlobalDeskTopNavMenuItemChild = {
    label: t('グロースについて相談する'),
    description: t('資金調達、販路拡大、デザイン、開発等お困りのことがありましたらいつでもお気軽にご連絡ください'),
    href:
      router.locale === 'ja'
        ? process.env.NEXT_PUBLIC_CONTACT_SALES_JA_URL
        : process.env.NEXT_PUBLIC_CONTACT_SALES_EN_URL,
    iconSvgNode: <MessageSmileSvg viewBox='0 0 24 24' />,
    isExternal: true,
  }

  const startUpEnglishMenuItem: GlobalDeskTopNavSubMenu[] = [
    {
      name: t('その他'),
      column: 0,
      subMenuItems: [contactSalesMenuItems],
    },
  ]

  const startUpJapaneseMenuItem: GlobalDeskTopNavSubMenu[] = [
    {
      name: t('その他'),
      column: 1, // 日本語メニューでは「その他」は2カラム目に表示
      subMenuItems: [
        {
          label: t('PROTOCOL IRM'),
          description: t('未入金リスクの回避と請求書を即現金化し今までにないキャッシュフローを支援'),
          href: 'https://toshika-lp.protocol.ooo/protocol-irm',
          iconSvgNode: <GiftSvg viewBox='0 0 24 24' />,
          isExternal: true,
        },
        {
          label: t('PROTOCOL Startup Debt'),
          description: t('財務データだけでなく定性データも活用して与信審査を行い、デット資金提供を支援 '),
          href: 'https://toshika-lp.protocol.ooo/protocol-startup-debt',
          iconSvgNode: <PieChartSvg viewBox='0 0 24 24' />,
          isExternal: true,
        },
        {
          label: t('補助金を探す'),
          description: t('成長とイノベーションを支える、多彩な補助金情報を見つけよう'),
          href: '/subsidies',
          iconSvgNode: <CurrencyYenSvg viewBox='0 0 24 24' />,
          isExternal: false,
        },
        {
          label: t('PROTOCOL Job Posting'),
          description: t('無料で求人票を公開し人材募集可能。スタートアップの人材採用を加速'),
          href: '/jobs',
          iconSvgNode: <GraduationHatSvg viewBox='0 0 24 24' />,
          isExternal: false,
        },
        contactSalesMenuItems,
      ],
    },
  ]

  const menuItems = rawMenuItems.map((item) => {
    if (item.key === MENU_KEY.STARTUPS && router.locale === 'ja') {
      // 日本語専用メニューを追加
      return {
        ...item,
        subMenus: [...item.subMenus, ...startUpJapaneseMenuItem],
      }
    }
    if (item.key === MENU_KEY.STARTUPS && router.locale === 'en') {
      return {
        ...item,
        subMenus: [...item.subMenus, ...startUpEnglishMenuItem],
      }
    }
    return item
  })

  return (
    <>
      <div className={styles.container}>
        {menuItems.map((item) => {
          return (
            <GlobalDesktopHeaderNavMenu
              key={item.name}
              name={item.name}
              subMenus={item.subMenus}
              isMultipleColumns={item.isMultipleColumns}
            />
          )
        })}
      </div>
    </>
  )
})
